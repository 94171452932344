<template>
  <div style="background-color: #171726;min-height: 100vh;">
    <div class="draw_top" :style="{ backgroundImage: 'url(img/draw_top.png)' }">
      <nav-bar class="title_top" :icon="'img/backw.png'" :textColor="'#ffffff'" :backColor="'none'" />
      <div class="draw_has">
        <p>可提现金额</p>
        <span>{{ current_Money }}</span>
      </div>
      <div style="display: flex;" class="buttons">
        <div class="people_btn" style="background-image: url('img/skr.png');" @click="goPeople"></div>
        <!-- <div class="tixian_btn" style="background-image: url('img/txjl.png')" @click="goNext"></div> -->
      </div>
    </div>
    <div class="draw_box" style="background-color: #171726;">
      <div class="display_flex justify-content_flex-justify align-items_center flex_wrap padding">
        <div class="draw_number margin-bottom-sm" v-for="(item, index) in drawNumber" :key="index"
          :style="{ backgroundImage: (drawCur == index ? 'url(img/draw_cur.png)' : 'url(img/draw_default.png)') }" @click="drawChange(index)">
          {{ item }}</div>
      </div>
      <div class="draw_submit" :style="{ backgroundImage: 'url(img/draw_sub.png)' }" @click="showPass = true"></div>
    </div>
    <div class="draw_rule padding-lr-sm margin-bottom-xl">
      <p>提现规则：</p>
      <p>1：您发起的提现在审核通过后至多48小时内完成打款；</p>
      <p> 2：您需要根据指引绑定手机号并且完善收款人信息后才可发起提现；</p>
      <p>3：为了您的资金安全，请务必提供正确的收款账户与收款人信息，否则可能导致打款失败；</p>
      <p>4：您可前往【钱包】-【管理】处修改您的收款账户；</p>
      <p>5：如有任何疑问请联系官方客服。</p>
    </div>

    <van-popup v-model="showPass" round :style="{ width: '80%' }">
      <div class="drawTxt margin-top">密码验证</div>
      <input class="draw_input padding-lr-sm" type="password" v-model="password" @blur="changeScroll">
      <div class="drawBtn display_flex align-items_center margin-top-xl">
        <div class="flex_1 clear_qx" @click="cancelProp">取消</div>
        <div class="flex_1 clear_qd" @click="drawSub">确定</div>
      </div>
    </van-popup>

  </div>
</template>

<script>
import "@/assets/css/base.css"
import "@/assets/css/index.css"
import { withdrawalApply } from "@/api/api";
import NavBar from '@/components/navBar.vue';
export default {
  name: 'withDraw',
  components: {
    NavBar
  },
  data() {
    return {
      current_Money: '',
      drawCur: 0,
      drawNumber: ['100', '200', '300', '500', '800', '1000'],
      showPass: false,
      password: ''
    };
  },
  created() {
    this.current_Money = this.$route.query.current_Money
  },
  methods: {
    drawChange(e) {
      this.drawCur = e
    },
    cancelProp() {
      this.password = ''
      this.showPass = false
    },
    drawSub() {
      if (this.password == '') {
        this.$toast.fail('请输入密码')
        return false
      }
      let Params = {
        amount: this.drawNumber[this.drawCur],
        password: this.password
      }
      withdrawalApply(Params).then(response => {
        const token = localStorage.getItem('access_token')
        this.$toast.success(response.msg)
        this.password = ''
        this.showPass = false
        this.$router.push({ path: '/index', query: { access_token: token } })
      },
        error => {
          this.password = ''
          this.showPass = false
          this.$toast.fail(error)
        }).catch((error) => {
          this.password = ''
          this.showPass = false
          this.$toast.fail(error)
        })
    },
    // 解决ios12的页面向上顶留空白
    changeScroll() {
      window.scrollTo(0, 0)
    },
    goPeople() {
      let param = '收款人信息'
      this.$bridge.callhandler('payeeDetail', param, (data) => {
        // 处理返回数据
      })
    },
    goNext() {
      this.$router.push({ path: '/record' })
    }

  },
};
</script>
<style>
/* html,
body {
  height: 100%;
  background-color: #171726;
} */
</style>